import Headline from '../headline/headline.component';

import './section-text.styles.scss';

function SectionText({ sectionTextData }) {

  return (
    <section className="grid section-text" aria-label="Vorstellung">
      <div className="cols">
        <div className="section-text-wrapper">
          <div className="content">

            <Headline content={sectionTextData} />
            <p>{sectionTextData.copy}</p>

          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionText;