import React, { useEffect, useCallback } from "react";
import { CSSTransition } from "react-transition-group";

import './modal.styles.scss';

const Modal = props => {
  const { onClose } = props;
  const closeOnEscapeKeyDown = useCallback((e) => {
    if ((e.charCode || e.keyCode) === 27) {
      onClose();
    }
  }, [onClose]);
  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, [closeOnEscapeKeyDown]);

  return (
    <CSSTransition in={props.show} unmountOnExit timeout={{ enter: 0, exit: 300 }}>
      <div className="modal" onClick={props.onClose}>
        <div className="modal-content" onClick={e => e.stopPropagation()}>
          <button onClick={props.onClose} className="button-close"><i className="fa-solid fa-xmark"></i></button>
          <div className="modal-body">{props.children}</div>
        </div>
      </div>
    </CSSTransition>
  )
}

export default Modal