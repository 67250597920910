import IconLinkInternal from '../../../components/icon-link/icon-link-internal.component';
import IconLinkExternal from '../../../components/icon-link/icon-link-external.component';

const Privacy = () => {

  return (
    <div className="modalContent" aria-label="Privacy">
      <h2>Privacy Policy</h2>

      <h3>1. Data protection at a glance</h3>

      <p className="category"><strong>General notes</strong></p>
      <p>The following notices provide a simple overview of what happens to your personal data when you visit this website. Personal data is any data that can be used to identify you personally. For detailed information on the subject of data protection, please refer to our privacy policy listed below this text.</p>

      <p className="category"><strong>Data collection on this website</strong></p>

      <p>Who is responsible for the data collection on this website?<br></br>
        Data processing on this website is carried out by the website operator. You can find the contact details of the website operator in the section "Information about the responsible party" in this data protection declaration.</p>

      <p>How do we collect your data?<br></br>
        On the one hand, your data is collected when you provide it to us. This can be, for example, data that you enter in a contact form. Other data is collected automatically or after your consent when you visit the website by our IT systems. This is mainly technical data (e.g. Internet browser, operating system or time of page view). The collection of this data takes place automatically as soon as you enter this website.</p>

      <p>What do we use your data for?<br></br>
        Some of the data is collected to ensure error-free provision of the website. Other data may be used to analyze your user behavior.</p>

      <p>What rights do you have regarding your data?<br></br>
        You have the right at any time to receive information free of charge about the origin, recipient and purpose of your stored personal data. You also have a right to request the correction or deletion of this data. If you have given your consent to data processing, you can revoke this consent at any time for the future. You also have the right to request the restriction of the processing of your personal data under certain circumstances. Furthermore, you have the right to lodge a complaint with the competent supervisory authority.</p>
      <p>You can contact us at any time with regard to this and other questions on the subject of data protection.</p>

      <h3>2. Hosting</h3>

      <p className="category"><strong>Hetzner</strong></p>
      <p>We host our website with Hetzner. The provider is Hetzner Online GmbH, Industriestr. 25, 91710 Gunzenhausen (hereinafter Hetzner).</p>
      <p>For details, please refer to Hetzner's privacy policy:&nbsp;
        <IconLinkExternal class="icon-link" icon="fa-solid fa-arrow-up-right-from-square" name="Hetzner Privacy Policy" url="https://www.hetzner.com/de/rechtliches/datenschutz" />
      </p>
      <p>The use of Hetzner is based on Art. 6 para. 1 lit. f GDPR. We have a legitimate interest in ensuring that our website is presented as reliably as possible. Insofar as a corresponding consent has been requested, processing is carried out exclusively on the basis of Art. 6 Para. 1 lit. a GDPR and § 25 Para. 1 TTDSG, insofar as the consent includes the storage of cookies or access to information in the user's terminal device (e.g. device fingerprinting) as defined by the TTDSG. The consent can be revoked at any time.</p>

      <p className="category"><strong>Order processing</strong></p>
      <p>We have concluded an order processing agreement (AVV) with the above-mentioned provider. This is a contract required by data protection law, which ensures that this provider only processes the personal data of our website visitors in accordance with our instructions and in compliance with the GDPR.</p>

      <h3>3. General notes and mandatory information</h3>

      <p className="category"><strong>Data protection</strong></p>
      <p>The operators of these pages take the protection of your personal data very seriously. We treat your personal data confidentially and in accordance with the statutory data protection regulations and this data protection declaration.</p>
      <p>When you use this website, various personal data are collected. Personal data is data with which you can be personally identified. This privacy policy explains what data we collect and what we use it for. It also explains how and for what purpose this is done.</p>
      <p>We would like to point out that data transmission on the Internet (e.g. when communicating by e-mail) can have security gaps. Complete protection of data against access by third parties is not possible.</p>

      <p className="category"><strong>Note on the responsible entity</strong></p>
      <p>The responsible party for data processing on this website is:</p>
      <p>Andreas Glosner<br />
        Uferstr. 49<br />
        55116 Mainz</p>

      <p>
        <IconLinkInternal class="icon-link mb-15" icon="fa-solid fa-envelope" name="hello@glosnerd.com" url="mailto:hello@glosnerd.com" /><br></br>
      </p>
      <p>The controller is the natural or legal person who alone or jointly with others determines the purposes and means of the processing of personal data (e.g. names, e-mail addresses, etc.).</p>

      <p className="category"><strong>Storage period</strong></p>
      <p>Unless a more specific storage period has been stated within this privacy policy, your personal data will remain with us until the purpose for processing the data no longer applies. If you assert a legitimate request for deletion or revoke your consent to data processing, your data will be deleted unless we have other legally permissible reasons for storing your personal data (e.g. retention periods under tax or commercial law); in the latter case, the data will be deleted once these reasons no longer apply.</p>

      <p className="category"><strong>General information on the legal basis for data processing on this website.</strong></p>
      <p>If you have consented to data processing, we process your personal data on the basis of Art. 6 (1) lit. a GDPR or Art. 9 (2) lit. a GDPR, if special categories of data are processed according to Art. 9 (1) GDPR. In the case of explicit consent to the transfer of personal data to third countries, the data processing is also based on Art. 49 (1) a GDPR. If you have consented to the storage of cookies or to the access to information in your terminal device (e.g. via device fingerprinting), the data processing is additionally carried out on the basis of Section 25 (1) TTDSG. The consent can be revoked at any time. If your data is required for the performance of a contract or for the implementation of pre-contractual measures, we process your data on the basis of Art. 6 para. 1 lit. b GDPR. Furthermore, if your data is required for the fulfillment of a legal obligation, we process it on the basis of Art. 6 para. 1 lit. c GDPR. Furthermore, the data processing may be carried out on the basis of our legitimate interest according to Art. 6 para. 1 lit. f GDPR. Information about the relevant legal basis in each individual case is provided in the following paragraphs of this privacy policy.</p>

      <p className="category"><strong>Revocation of your consent to data processing</strong></p>
      <p>Many data processing operations are only possible with your express consent. You can revoke consent you have already given at any time. The legality of the data processing carried out until the revocation remains unaffected by the revocation.</p>

      <p className="category"><strong>Right to object to the collection of data in specific cases and to direct marketing (Art. 21 GDPR)</strong></p>
      <p>IF THE DATA PROCESSING IS BASED ON ART. 6 ABS. 1 LIT. E OR F GDPR, YOU HAVE THE RIGHT TO OBJECT TO THE PROCESSING OF YOUR PERSONAL DATA AT ANY TIME FOR REASONS ARISING FROM YOUR PARTICULAR SITUATION; THIS ALSO APPLIES TO PROFILING BASED ON THESE PROVISIONS. THE RESPECTIVE LEGAL BASIS ON WHICH PROCESSING IS BASED CAN BE FOUND IN THIS PRIVACY POLICY. IF YOU OBJECT, WE WILL NO LONGER PROCESS YOUR PERSONAL DATA CONCERNED UNLESS WE CAN DEMONSTRATE COMPELLING LEGITIMATE GROUNDS FOR THE PROCESSING WHICH OVERRIDE YOUR INTERESTS, RIGHTS AND FREEDOMS, OR THE PROCESSING IS FOR THE PURPOSE OF ASSERTING, EXERCISING OR DEFENDING LEGAL CLAIMS (OBJECTION UNDER ARTICLE 21(1) GDPR).</p>
      <p>IF YOUR PERSONAL DATA ARE PROCESSED FOR THE PURPOSE OF DIRECT MARKETING, YOU HAVE THE RIGHT TO OBJECT AT ANY TIME TO THE PROCESSING OF PERSONAL DATA CONCERNING YOU FOR THE PURPOSE OF SUCH MARKETING; THIS ALSO APPLIES TO PROFILING INSOFAR AS IT IS RELATED TO SUCH DIRECT MARKETING. IF YOU OBJECT, YOUR PERSONAL DATA WILL SUBSEQUENTLY NO LONGER BE USED FOR THE PURPOSE OF DIRECT MARKETING (OBJECTION PURSUANT TO ARTICLE 21 (2) GDPR).</p>

      <p className="category"><strong>Right of appeal to the competent supervisory authority</strong></p>
      <p>In the event of violations of the GDPR, data subjects shall have a right of appeal to a supervisory authority, in particular in the Member State of their habitual residence, their place of work or the place of the alleged violation. The right of appeal is without prejudice to other administrative or judicial remedies.</p>

      <p className="category"><strong>Right to data portability</strong></p>
      <p>You have the right to have data that we process automatically on the basis of your consent or in fulfillment of a contract handed over to you or to a third party in a common, machine-readable format. If you request the direct transfer of the data to another controller, this will only be done insofar as it is technically feasible.</p>

      <p className="category"><strong>Information, deletion and correction</strong></p>
      <p>Within the framework of the applicable legal provisions, you have the right at any time to free information about your stored personal data, its origin and recipient and the purpose of data processing and, if necessary, a right to correction or deletion of this data. For this purpose, as well as for further questions on the subject of personal data, you can contact us at any time.</p>

      <p className="category"><strong>Right to restriction of processing</strong></p>
      <p>You have the right to request the restriction of the processing of your personal data. For this purpose, you can contact us at any time. The right to restriction of processing exists in the following cases:</p>
      <ul>
        <li>If you dispute the accuracy of your personal data stored by us, we usually need time to verify this. For the duration of the review, you have the right to request the restriction of the processing of your personal data.</li>
        <li>If the processing of your personal data happened/is happening unlawfully, you can request the restriction of data processing instead of deletion.</li>
        <li>If we no longer need your personal data, but you need it to exercise, defend or assert legal claims, you have the right to request restriction of the processing of your personal data instead of erasure.</li>
        <li>If you have lodged an objection pursuant to Art. 21 (1) GDPR, a balancing of your and our interests must be carried out. As long as it has not yet been determined whose interests prevail, you have the right to request the restriction of the processing of your personal data.</li>
      </ul>
      <p>If you have restricted the processing of your personal data, this data may - apart from being stored - only be processed with your consent or for the assertion, exercise or defense of legal claims or for the protection of the rights of another natural or legal person or for reasons of an important public interest of the European Union or a Member State.</p>

      <p className="category"><strong>SSL or TLS encryption</strong></p>
      <p>For security reasons and to protect the transmission of confidential content, such as orders or requests that you send to us as the site operator, this site uses SSL or TLS encryption. You can recognize an encrypted connection by the fact that the address line of the browser changes from "http://" to "https://" and by the lock symbol in your browser line.</p>
      <p>If SSL or TLS encryption is activated, the data you transmit to us cannot be read by third parties.</p>

      <p className="title"><strong>4. Data collection on this website</strong></p>

      <p className="category"><strong>Cookies</strong></p>
      <p>Our internet pages use so-called "cookies". Cookies are small text files and do not cause any damage to your terminal device. They are stored either temporarily for the duration of a session (session cookies) or permanently (permanent cookies) on your end device. Session cookies are automatically deleted at the end of your visit. Permanent cookies remain stored on your end device until you delete them yourself or until they are automatically deleted by your web browser.</p>
      <p>In some cases, cookies from third-party companies may also be stored on your terminal device when you enter our site (third-party cookies). These enable us or you to use certain services of the third-party company (e.g. cookies for processing payment services).</p>
      <p>Cookies have various functions. Many cookies are technically necessary, as certain website functions would not work without them (e.g. the shopping cart function or the display of videos). Other cookies are used to evaluate user behavior or display advertising.</p>
      <p>Cookies that are necessary to carry out the electronic communication process, to provide certain functions that you have requested (e.g. for the shopping cart function) or to optimize the website (e.g. cookies to measure the web audience) (necessary cookies) are stored on the basis of Art. 6 (1) lit. f GDPR, unless another legal basis is specified. The website operator has a legitimate interest in storing necessary cookies for the technically error-free and optimized provision of its services. If consent to the storage of cookies and comparable recognition technologies has been requested, processing is carried out exclusively on the basis of this consent (Art. 6 para. 1 lit. a GDPR and § 25 para. 1 TTDSG); consent can be revoked at any time.</p>
      <p>You can set your browser so that you are informed about the setting of cookies and only allow cookies in individual cases, exclude the acceptance of cookies for certain cases or in general and activate the automatic deletion of cookies when closing the browser. If cookies are deactivated, the functionality of this website may be limited.</p>
      <p>Insofar as cookies are used by third-party companies or for analysis purposes, we will inform you separately about this within the framework of this data protection declaration and, if necessary, request your consent.</p>

      <p className="category"><strong>Inquiry by e-mail, telephone or fax</strong></p>
      <p>If you contact us by e-mail, telephone or fax, your inquiry including all resulting personal data (name, inquiry) will be stored and processed by us for the purpose of processing your request. We do not pass on this data without your consent.</p>
      <p>The processing of this data is based on Art. 6 (1) lit. b GDPR, if your request is related to the performance of a contract or is necessary for the implementation of pre-contractual measures. In all other cases, the processing is based on our legitimate interest in the effective processing of the requests sent to us (Art. 6 (1) (f) GDPR) or on your consent (Art. 6 (1) (a) GDPR) if this has been requested; the consent can be revoked at any time.</p>
      <p>The data you send to us via contact requests will remain with us until you request us to delete it, revoke your consent to store it or the purpose for storing the data no longer applies (e.g. after your request has been processed). Mandatory legal provisions - in particular legal retention periods - remain unaffected.</p>

      <h3>5. Plugins und Tools</h3>

      <p className="category"><strong>Google Web Fonts (local hosting)</strong></p>
      <p>This site uses so-called web fonts provided by Google for the uniform display of fonts. The Google Fonts are installed locally. There is no connection to Google servers.</p>
      <p>For more information on Google Web Fonts, please see the&nbsp;
        <IconLinkExternal class="icon-link" icon="fa-solid fa-arrow-up-right-from-square" name="Google Fonts FAQ" url="https://developers.google.com/fonts/faq" />&nbsp;
        and Google's privacy policy:&nbsp;
        <IconLinkExternal class="icon-link" icon="fa fa-arrow-up-right-from-square" name="Google Privacy" url="https://policies.google.com/privacy?hl=de" />
      </p>

      <p className="category"><strong>Font Awesome (local hosting)</strong></p>
      <p>This site uses Font Awesome for consistent font rendering. Font Awesome is locally installed. There is no connection to Fonticons, Inc. servers.</p>
      <p>For more information about Font Awesome, please see the Font Awesome privacy policy at:&nbsp;
        <IconLinkExternal class="icon-link" icon="fa-solid fa-arrow-up-right-from-square" name="Fontawesome Privacy" url="https://fontawesome.com/privacy" />
      </p>
    </div>
  );
};

export default Privacy;