import './icon-link.styles.scss';

function IconLink({ icon, url }) {
  return (
    <a className="icon-link" href={url} target="_blank" rel="noreferrer">
      <i className={icon}></i>
    </a>
  );
};

export default IconLink;