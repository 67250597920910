import React, { useState } from 'react';

import Modal from '../../components/modal/modal.component'
import ModalImprint from './modal-content/imprint.modal'
import ModalPrivacy from './modal-content/privacy.modal'

function ModalHandler({ modalContent, modalTitle }) {

  const setModal = function () {
    switch (modalContent) {
      case "Imprint":
        return <ModalImprint />
      case "Privacy":
        return <ModalPrivacy />
      default:
    }
  }

  const [show, setShow] = useState(false)

  return (
    <div>
      <button className="linkstyle" onClick={() => setShow(true)}>{modalTitle}</button>

      <Modal onClose={() => setShow(false)} show={show}>
        {setModal(modalContent)}
      </Modal>
    </div>
  )
}

export default ModalHandler;