import { Fragment } from 'react';

import SectionHero from '../../components/section-hero/section-hero.components';
import SectionText from '../../components/section-text/section-text.components';
import SectionSkills from '../../components/section-skills/section-skills.components';
import SectionReferences from '../../components/section-references/section-references.components';
import SectionTextImage from '../../components/section-text-image/section-text-image.components';
import SectionGame from '../../components/section-game/section-game.components';
import CtaBar from '../../components/cta-bar/cta-bar.component';
import IconBar from '../../components/icon-bar/icon-bar.component';
import Footer from '../../components/footer/footer.component';

import '../../index.scss';

const Home = () => {

  const sectionTextData = {
    headline: 'Welcome',
    title: 'Assets & Games',
    copy: 'This website is currently under development. Please visit my social media in the meantime! In the future, you will be able to find information about current developments of 3D assets and games here, as well as purchase them through the webshop. Stay tuned!',
  };

  const sectionHeroData = {
    headline: 'Andreas Glosner',
    title: 'Web & Game Developer',
    copy: '',
  };

  const sectionSkillsData = {
    headline: 'Skills & Referenzen',
    title: 'Überzeuge dich von mir',
    copy: '',
  };

  const softskillData = [
    {
      id: 1,
      name: 'Optimistisch',
    },
    {
      id: 2,
      name: 'Zuverlässig',
    },
    {
      id: 3,
      name: 'Motiviert',
    },
  ];

  const hardskillData = [
    {
      webdev: [
        {
          id: 1,
          name: 'Web Development',
          stars: 0
        },
        {
          id: 2,
          name: 'HTML',
          stars: 3
        },

        {
          id: 3,
          name: 'CSS / SCSS',
          stars: 3
        },
        {
          id: 4,
          name: 'JavaScript',
          stars: 2
        },
        {
          id: 5,
          name: 'React',
          stars: 2
        },
        {
          id: 6,
          name: 'WordPress',
          stars: 2
        },
        {
          id: 7,
          name: 'PHP',
          stars: 1
        },
      ],
      gamedev: [
        {
          id: 1,
          name: 'Game Development',
          stars: 0
        },
        {
          id: 2,
          name: 'Blender',
          stars: 2
        },
        {
          id: 3,
          name: 'Unity',
          stars: 2
        },
        {
          id: 4,
          name: 'C#',
          stars: 2
        },
        {
          id: 5,
          name: 'Unreal Engine 5',
          stars: 1
        },
      ],
      other: [
        {
          id: 1,
          name: 'Sonstiges',
          stars: 0
        },
        {
          id: 2,
          name: 'Mailchimp',
          stars: 3
        },
        {
          id: 3,
          name: 'Photoshop',
          stars: 2
        },
        {
          id: 4,
          name: 'Lightroom',
          stars: 2
        },
        {
          id: 5,
          name: 'Premiere Pro',
          stars: 2
        },
        {
          id: 6,
          name: 'Fotografie',
          stars: 2
        },
      ],
    }
  ];

  const ctaBarData = {
    url: 'mailto:hello@andreas-glosner.de',
    name: 'Kontakt aufnehmen',
    icon: 'fa-solid fa-envelope',
  };

  const sectionTextImageData = {
    headline: 'Lorem Ipsum',
    title: 'How To Lorem',
    copy: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
  };

  const sectionGameData = {
    headline: '- How To Hire -',
    title: 'Spiel mein Minigame',
    class: 'underline',
    copy: '--- HIER GAME EINFÜGEN ---',
  };

  return (
    <Fragment>
      {/* <SectionHero sectionHeroData={sectionHeroData} /> */}
      <SectionText sectionTextData={sectionTextData} />
      {/* <SectionSkills sectionSkillsData={sectionSkillsData} softskillData={softskillData} hardskillData={hardskillData}/>
      <SectionReferences />
      <SectionTextImage sectionTextImageData={sectionTextImageData}/>
      <SectionGame sectionGameData={sectionGameData} /> */}
      <IconBar />
      {/* <CtaBar ctaBarData={ctaBarData} /> */}
      <Footer />
    </Fragment>
  )
}

export default Home;