import ModalHandler from '../../components/modal/modal-handler.component';

import ag_logo_white from '../../assets/images/ag_logo_white.png';
import gn_logo_white from '../../assets/images/gn_logo_white.png';

import './footer.styles.scss';

function Footer() {

  return (
    <footer className="grid footer" aria-label="Footer">
      <div className="cols-10 {">
        <div className="footer-wrapper">
          <div className="content">

            <div className="logo-wrapper">
              <img src={gn_logo_white} alt="logo" width="100px" height="100px"></img>
            </div>

            <div className="details">
              <div className="nav-wrapper">
                <nav className="footer-nav">
                  <ModalHandler modalContent={"Imprint"} modalTitle="Imprint" />
                  <div className="seperator"></div>
                  <ModalHandler modalContent={"Privacy"} modalTitle="Privacy" />
                </nav>
                <p className="copyright">Made by Andreas Glosner &#169; 2023</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </footer >
  );
};

export default Footer;