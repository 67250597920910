import './icon-link.styles.scss';

function IconLinkExternal({ icon, name, url }) {
  return (
    <a className="icon-link external" href={url} target="_blank" rel="noreferrer">
      <span>{name}<i className={icon}></i></span>
    </a>
  );
};

export default IconLinkExternal;