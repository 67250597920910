import IconLink from '../icon-link/icon-link.component'


import './icon-bar.styles.scss'
function IconBar() {
  return (
    <section className="icon-bar" aria-label="Social Media Bar">
      <div className="content">
        <div className="icon-wrapper">

          <IconLink class="icon-link mb-30" icon="fa-brands fa-twitter" url="https://twitter.com/GlosNerd" />
          <IconLink class="icon-link mb-30" icon="fa-brands fa-youtube" url="https://www.youtube.com/channel/UC68esulqCb0yh9ftuukUzbw" />
          <IconLink class="icon-link mb-30" icon="fa-brands fa-instagram" url="https://www.instagram.com/GlosNerd" />
          <IconLink class="icon-link mb-30" icon="fa-brands fa-facebook-square" url="https://www.facebook.com/GlosNerd" />

        </div>
      </div>
    </section>
  );
};

export default IconBar;