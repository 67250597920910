import './headline.styles.scss';

function Headline({ content }) {

  return (
    <div className="headline">
      <p className="subline">{content.title}</p>
      <h2 className="underline">{content.headline}</h2>
    </div>
  );
};

export default Headline;