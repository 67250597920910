import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Navigation from '../routes/navigation/navigation.component'
import Home from '../routes/home/home.component'
import Assets from '../routes/assets/assets.component'
import Games from '../routes/games/games.component'
import Shop from '../routes/shop/shop.component'

const App = () => {
  return (
    <Routes>

        <Route index element={<Home />} />

      {/* <Route path='/' element={<Navigation />}>
        <Route index element={<Home />} />
        <Route path='assets' element={<Assets />} />
        <Route path='games' element={<Games />} />
        <Route path='shop' element={<Shop />} />
      </Route> */}
    </Routes>
  )
}

export default App;