import './icon-link.styles.scss';

function IconLinkInternal({ icon, name, url }) {
  return (
    <a className="icon-link internal" href={url} target="_blank" rel="noreferrer">
      <span><i className={icon}></i>{name}</span>
    </a>
  );
};

export default IconLinkInternal;